<template>
  <b-card>
    <b-card-header>
      <strong>{{ $t('AppUser.edit.notifications') }}</strong>
    </b-card-header>
    <b-card-body>
      <div
        v-for="notification in notifications"
        :key="notification.notificacionId"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="45"
              :variant="notification.notificacionTipo.tipo"
            >
              <feather-icon :icon="notification.notificacionTipo.icono" />
            </b-avatar>
          </template>
          <p class="media-heading mr-0">
            <app-collapse>
              <app-collapse-item :title="`${notification.creacion.replace('T', ' - ').substring(0, 18)} - ${(notification.subtitulo === '' ? notification.titulo : notification.subtitulo)}`">
                <div v-html="notification.correoCuerpo" />
              </app-collapse-item>
            </app-collapse>
          </p>
        </b-media>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BMedia,
  BAvatar,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import accountService from '@/services/account.service'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BMedia,
    BAvatar,

    AppCollapse,
    AppCollapseItem,
  },
  setup() {
    /* Services */
    const {
      fetchNotifications,
      fetchNotificationsReadAll,
    } = accountService()
    const notifications = ref([])
    fetchNotifications(data => {
      notifications.value = data.notificaciones
    })
    fetchNotificationsReadAll()
    return {
      notifications,
    }
  },
}
</script>

<style>

</style>
